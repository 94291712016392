:root {
  --cw: min(1440px, 100vw);
  --scale: var(--cw) / 1440;
  --hpad: calc(9.5 / 100 * var(--cw));
  --vpad: calc(6.25 / 100 * var(--cw));
  --vpad-small: calc(3.8 / 100 * var(--cw));
  --vpad-xsmall: calc(2.6 / 100 * var(--cw));
}

* {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
}

html, body {
  background-color: #fff;
}

section, footer {
  width: 100%;

  > span {
    display: flex;
    flex-direction: row;
    max-width: 1440px;
    margin: 0 auto;
  }
}

.under-construction {
  background-color: #fff;

  > span {
    align-items: center;
    padding: var(--vpad-small) var(--hpad);
    gap: calc(56 * var(--scale));
  }

  h1 {
    --size: calc(130 * var(--scale));
    text-indent: -9999px;
    width: var(--size); height: var(--size);
    min-width: var(--size); min-height: var(--size);
    background-image: url(../img/logo-tree.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }

  h2 {
    font-size: calc(30 * var(--scale));
    font-weight: normal;
  }
}

.welcome {
  background-color: #EFF1EE;

  > span {
    justify-content: space-between;
    padding: var(--vpad) var(--hpad);
    gap: var(--hpad);
  }

  h3 {
    font-size: calc(50 * var(--scale));
    font-weight: normal;
    display: flex;
    flex-direction: column;

    em {
      display: block;
      text-indent: -9999px;
      width: calc(37.15 / 100 * var(--cw));
      height: calc(36.16 / 100 * var(--cw));
      background-image: url(../img/logo-title.png);
      background-size: contain;
    }
  }

  p {
    max-width: calc(500 * var(--scale));
    font-size: calc(35 * var(--scale));
    line-height: calc(48 * var(--scale));
  }
}

.about {
  background-color: #FF5741;

  > span {
    justify-content: space-between;
    padding: var(--vpad) var(--hpad);
    gap: var(--hpad);
    position: relative;
  }

  h3 {
    font-size: calc(50 * var(--scale));
    line-height: calc(68 * var(--scale));
    font-weight: 900;
    color: #fff;

    em {
      font-style: normal;
      color: #F7C800;
    }
  }

  p {
    max-width: calc(500 * var(--scale));
    font-size: calc(35 * var(--scale));
    line-height: calc(48 * var(--scale));
    color: #fff;
  } 
 
  .smiley {
    position: absolute;
    width: calc(213 * var(--scale));
    height: calc(213 * var(--scale));
    left: calc(430 * var(--scale));
    top: calc(180 * var(--scale));
    background-image: url(../img/smiley.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.nav {
  background-color: #FF92B4;

  > span {
    justify-content: space-between;
    padding: var(--vpad-xsmall) var(--hpad);
    gap: var(--vpad-xsmall);
    flex-direction: column;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0; bottom: 0;
      width: calc(208 * var(--scale));
      height: calc(174 * var(--scale));
      background-image: url(../img/house.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  h3 {
    font-size: calc(50 * var(--scale));
    line-height: calc(68 * var(--scale));
    font-weight: 900;
    color: #D91A13;
  }

  nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: calc(52 * var(--scale));
    margin: 0 auto calc(24 * var(--scale));
    justify-content: space-evenly;
    padding: 0 calc(56 * var(--scale));
  }

  a {
    font-size: calc(30 * var(--scale));
    line-height: calc(41 * var(--scale));
    text-transform: uppercase;
    color: #D91A13;
    text-decoration: none;
    display: block;
    width: calc(380 * var(--scale));
    height: calc(56 * var(--scale));
    border: solid 1px #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: calc(60 * var(--scale));

    &:hover {
      background-color: #D91A13;
      border-color: #D91A13;
      color: #fff;
    }
  }
}

.imagery {
  width: 100dvw;
  height: 33dvw;
  background: #A0CBF0 url(../img/school.avif);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 80%;
}

footer {
  background-color: #F7F2E9;
  color: #D91A13;
  font-size: calc(32 * var(--scale));
  line-height: calc(44 * var(--scale));

  > span {
    padding: var(--vpad) var(--hpad);
    gap: var(--hpad);
    position: relative;

    &:after {
      content: '';
      position: absolute;
      right: calc(100 * var(--scale)); 
      top: calc(60 * var(--scale));
      width: calc(107 * var(--scale));
      height: calc(129.5 * var(--scale));
      background-image: url(../img/heart.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  ul {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
  }

  a {
    color: #D91A13;
    text-decoration: none;
    &:hover {
      text-decoration-line: underline;
      text-decoration-style: wavy;
      text-decoration-thickness: 1px;
    }
  }
}
