@media (max-width: 720px) {

  .under-construction {
    > span {
      padding: 32px 28px;
      gap: 32px;  
    }

    h1 {
      --size: 46px;
    }
    
    h2 {
      font-size: 14px;
      line-height: 19px;
    }
  }

  .welcome {
    > span {
      padding: 56px 28px 80px;
      flex-direction: column;
      gap: 44px;  
    }

    h3 {
      font-size: 25px;
      line-height: 34px;

      em {
        // width: 234px; 
        // height: 231.5px;
        width: 66dvw;
        height: calc(66dvw * 231.5 / 234);
      }
    }

    p {
      font-size: 20px;
      line-height: 27px;
      max-width: none;
    }
  }


  .about {
    > span {
      padding: 80px 28px 80px;
      flex-direction: column;
      gap: 0;  
    }

    h3 {
      font-size: 38px;
      line-height: 48px;
    }

    .smiley {
      --size: calc(100dvw * 161 / 360);
      position: static;
      margin-top: 56px;
      margin-bottom: 80px;
      width: var(--size); height: var(--size);
      align-self: flex-end;
      margin-right: 32px;
    }

    p {
      font-size: 20px;
      line-height: 27px;
      max-width: none;
    }
  }

  .nav {
    > span {
      padding: 70px 28px 270px;
      flex-direction: column;
      gap: 60px;  
      
      &:after {
        width: 170.5px;
        height: 143px;
        left: 24px;
      }
    }

    h3 {
      font-size: calc(min(38px, 100dvw / 380 * 38));
      line-height: calc(min(48px, 100dvw / 380 * 48));
    }

    nav { 
      padding: 0;
      gap: 58px;
    }

    a {
      width: 100%; height: 44px;
      font-size: calc(min(24px, 100dvw / 380 * 24));
      line-height: calc(min(33px, 100dvw / 380 * 33));
    }
  }

  .imagery {
    height: 56dvw;
    image-rendering: optimizeQuality;;
  }

  footer {
    font-size: 16px;
    line-height: 22px;
    overflow: hidden;

    > span {
      padding: 80px 28px 80px;
      flex-direction: column;
      gap: 0;  

      &:after {
        width: 83px; height: 105px;
        top: 220px; right: auto; 
        left: calc(max(240px, 100dvw - 130px));
      }
    }

    ul {
      flex-direction: column;
      gap: 80px;
    }
  }

}