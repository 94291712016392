html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: Avenir;
  src: url("Avenir-Roman.08b75c39.eot");
  src: local(Avenir Roman), local(Avenir-Roman), url("Avenir-Roman.08b75c39.eot#iefix") format("embedded-opentype"), url("Avenir-Roman.58c2ac54.woff2") format("woff2"), url("Avenir-Roman.60c7edcd.woff") format("woff"), url("Avenir-Roman.0957d09c.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Avenir;
  src: url("Avenir-Black.3d0358c7.eot");
  src: local(Avenir Black), local(Avenir-Black), url("Avenir-Black.3d0358c7.eot#iefix") format("embedded-opentype"), url("Avenir-Black.984c5593.woff2") format("woff2"), url("Avenir-Black.9b028c41.woff") format("woff"), url("Avenir-Black.f87c64fe.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Avenir;
  src: url("Avenir-Medium.466cf20c.eot");
  src: local(Avenir Medium), local(Avenir-Medium), url("Avenir-Medium.466cf20c.eot#iefix") format("embedded-opentype"), url("Avenir-Medium.9b057537.woff2") format("woff2"), url("Avenir-Medium.f58747c5.woff") format("woff"), url("Avenir-Medium.1be845f8.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

:root {
  --srot: 5deg;
  --srotm: calc(var(--srot) * -1);
  --srot2: 10deg;
  --srot2m: calc(var(--srot2) * -1);
}

@keyframes twist {
  0%, 100% {
    transform: rotate(0);
  }

  10% {
    transform: rotate(var(--srot2m));
  }

  25% {
    transform: rotate(var(--srot2));
  }

  40% {
    transform: rotate(var(--srotm));
  }

  55% {
    transform: rotate(var(--srot));
  }

  70% {
    transform: rotate(0);
  }
}

@keyframes heartbeat {
  0%, 100% {
    transform: scale(1);
  }

  15% {
    transform: scale(1.05);
  }

  30% {
    transform: scale(1);
  }

  45% {
    transform: scale(1.05);
  }

  60% {
    transform: scale(1);
  }
}

.smiley {
  animation: 4s ease-in-out infinite twist;
}

footer > span:after {
  animation: 3s ease-in-out infinite heartbeat;
}

:root {
  --cw: min(1440px, 100vw);
  --scale: var(--cw) / 1440;
  --hpad: calc(9.5 / 100 * var(--cw));
  --vpad: calc(6.25 / 100 * var(--cw));
  --vpad-small: calc(3.8 / 100 * var(--cw));
  --vpad-xsmall: calc(2.6 / 100 * var(--cw));
}

* {
  box-sizing: border-box;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

html, body {
  background-color: #fff;
}

section, footer {
  width: 100%;
}

section > span, footer > span {
  flex-direction: row;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
}

.under-construction {
  background-color: #fff;
}

.under-construction > span {
  padding: var(--vpad-small) var(--hpad);
  align-items: center;
  gap: calc(56 * var(--scale));
}

.under-construction h1 {
  --size: calc(130 * var(--scale));
  text-indent: -9999px;
  width: var(--size);
  height: var(--size);
  min-width: var(--size);
  min-height: var(--size);
  background-image: url("logo-tree.7d20ceca.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.under-construction h2 {
  font-size: calc(30 * var(--scale));
  font-weight: normal;
}

.welcome {
  background-color: #eff1ee;
}

.welcome > span {
  padding: var(--vpad) var(--hpad);
  justify-content: space-between;
  gap: var(--hpad);
}

.welcome h3 {
  font-size: calc(50 * var(--scale));
  flex-direction: column;
  font-weight: normal;
  display: flex;
}

.welcome h3 em {
  text-indent: -9999px;
  width: calc(.3715 * var(--cw));
  height: calc(.3616 * var(--cw));
  background-image: url("logo-title.303ca086.png");
  background-size: contain;
  display: block;
}

.welcome p {
  max-width: calc(500 * var(--scale));
  font-size: calc(35 * var(--scale));
  line-height: calc(48 * var(--scale));
}

.about {
  background-color: #ff5741;
}

.about > span {
  padding: var(--vpad) var(--hpad);
  justify-content: space-between;
  gap: var(--hpad);
  position: relative;
}

.about h3 {
  font-size: calc(50 * var(--scale));
  line-height: calc(68 * var(--scale));
  color: #fff;
  font-weight: 900;
}

.about h3 em {
  color: #f7c800;
  font-style: normal;
}

.about p {
  max-width: calc(500 * var(--scale));
  font-size: calc(35 * var(--scale));
  line-height: calc(48 * var(--scale));
  color: #fff;
}

.about .smiley {
  width: calc(213 * var(--scale));
  height: calc(213 * var(--scale));
  left: calc(430 * var(--scale));
  top: calc(180 * var(--scale));
  background-image: url("smiley.9a76e049.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
}

.nav {
  background-color: #ff92b4;
}

.nav > span {
  padding: var(--vpad-xsmall) var(--hpad);
  justify-content: space-between;
  gap: var(--vpad-xsmall);
  flex-direction: column;
  position: relative;
}

.nav > span:after {
  content: "";
  width: calc(208 * var(--scale));
  height: calc(174 * var(--scale));
  background-image: url("house.7b4743b2.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 0;
  left: 0;
}

.nav h3 {
  font-size: calc(50 * var(--scale));
  line-height: calc(68 * var(--scale));
  color: #d91a13;
  font-weight: 900;
}

.nav nav {
  gap: calc(52 * var(--scale));
  margin: 0 auto calc(24 * var(--scale));
  padding: 0 calc(56 * var(--scale));
  flex-flow: wrap;
  justify-content: space-evenly;
  display: flex;
}

.nav a {
  font-size: calc(30 * var(--scale));
  line-height: calc(41 * var(--scale));
  text-transform: uppercase;
  color: #d91a13;
  width: calc(380 * var(--scale));
  height: calc(56 * var(--scale));
  border-radius: calc(60 * var(--scale));
  border: 1px solid #fff;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.nav a:hover {
  color: #fff;
  background-color: #d91a13;
  border-color: #d91a13;
}

.imagery {
  background: #a0cbf0 url("school.87b875fa.avif") 50% 80% / cover no-repeat;
  width: 100dvw;
  height: 33dvw;
}

footer {
  color: #d91a13;
  font-size: calc(32 * var(--scale));
  line-height: calc(44 * var(--scale));
  background-color: #f7f2e9;
}

footer > span {
  padding: var(--vpad) var(--hpad);
  gap: var(--hpad);
  position: relative;
}

footer > span:after {
  content: "";
  right: calc(100 * var(--scale));
  top: calc(60 * var(--scale));
  width: calc(107 * var(--scale));
  height: calc(129.5 * var(--scale));
  background-image: url("heart.ddab7d64.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
}

footer ul {
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  display: flex;
}

footer a {
  color: #d91a13;
  text-decoration: none;
}

footer a:hover {
  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-thickness: 1px;
}

@media (width <= 720px) {
  .under-construction > span {
    gap: 32px;
    padding: 32px 28px;
  }

  .under-construction h1 {
    --size: 46px;
  }

  .under-construction h2 {
    font-size: 14px;
    line-height: 19px;
  }

  .welcome > span {
    flex-direction: column;
    gap: 44px;
    padding: 56px 28px 80px;
  }

  .welcome h3 {
    font-size: 25px;
    line-height: 34px;
  }

  .welcome h3 em {
    width: 66dvw;
    height: 65.2949dvw;
  }

  .welcome p {
    max-width: none;
    font-size: 20px;
    line-height: 27px;
  }

  .about > span {
    flex-direction: column;
    gap: 0;
    padding: 80px 28px;
  }

  .about h3 {
    font-size: 38px;
    line-height: 48px;
  }

  .about .smiley {
    --size: calc(100dvw * 161 / 360);
    width: var(--size);
    height: var(--size);
    align-self: flex-end;
    margin-top: 56px;
    margin-bottom: 80px;
    margin-right: 32px;
    position: static;
  }

  .about p {
    max-width: none;
    font-size: 20px;
    line-height: 27px;
  }

  .nav > span {
    flex-direction: column;
    gap: 60px;
    padding: 70px 28px 270px;
  }

  .nav > span:after {
    width: 170.5px;
    height: 143px;
    left: 24px;
  }

  .nav h3 {
    font-size: min(38px, 10dvw);
    line-height: min(48px, 12.6316dvw);
  }

  .nav nav {
    gap: 58px;
    padding: 0;
  }

  .nav a {
    width: 100%;
    height: 44px;
    font-size: min(24px, 6.31579dvw);
    line-height: min(33px, 8.68421dvw);
  }

  .imagery {
    height: 56dvw;
    image-rendering: optimizeQuality;
  }

  footer {
    font-size: 16px;
    line-height: 22px;
    overflow: hidden;
  }

  footer > span {
    flex-direction: column;
    gap: 0;
    padding: 80px 28px;
  }

  footer > span:after {
    width: 83px;
    height: 105px;
    top: 220px;
    left: max(240px, 100dvw - 130px);
    right: auto;
  }

  footer ul {
    flex-direction: column;
    gap: 80px;
  }
}
/*# sourceMappingURL=index.96af104c.css.map */
