@charset "UTF-8";

@font-face {
  font-family: 'Avenir';
  src: url("../fonts/Avenir-Roman.eot");
  src: local("Avenir Roman"), local("Avenir-Roman"), url("../fonts/Avenir-Roman.eot?#iefix") format("embedded-opentype"), url("../fonts/Avenir-Roman.woff2") format("woff2"), url("../fonts/Avenir-Roman.woff") format("woff"), url("../fonts/Avenir-Roman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url("../fonts/Avenir-Black.eot");
  src: local("Avenir Black"), local("Avenir-Black"), url("../fonts/Avenir-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Avenir-Black.woff2") format("woff2"), url("../fonts/Avenir-Black.woff") format("woff"), url("../fonts/Avenir-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url("../fonts/Avenir-Medium.eot");
  src: local("Avenir Medium"), local("Avenir-Medium"), url("../fonts/Avenir-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Avenir-Medium.woff2") format("woff2"), url("../fonts/Avenir-Medium.woff") format("woff"), url("../fonts/Avenir-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}