:root {
  --srot: 5deg;
  --srotm: calc(var(--srot) * -1);
  --srot2: 10deg;
  --srot2m: calc(var(--srot2) * -1);
}

@keyframes twist {
  0%, 100% { transform: rotate(0deg); }
  10% { transform: rotate(var(--srot2m)); }
  25% { transform: rotate(var(--srot2)); }
  40% { transform: rotate(var(--srotm)); }
  55% { transform: rotate(var(--srot)); }
  70% { transform: rotate(0deg); }
}

@keyframes heartbeat {
  0%, 100% { transform: scale(1); }
  15% { transform: scale(1.05); }
  30% { transform: scale(1); }
  45% { transform: scale(1.05); }
  60% { transform: scale(1); }
}

.smiley {
  animation: twist 4s ease-in-out infinite;
}

footer > span:after {
  animation: heartbeat 3s ease-in-out infinite;
}
